import { THEME_ENUM } from 'constants/theme.constant'

export default function GetThemeConfig() {



    let themeConfig = {
        themeColor: 'indigo',
        direction: THEME_ENUM.DIR_LTR,
        mode: THEME_ENUM.MODE_LIGHT,
        locale: 'en',
        primaryColorLevel: 600,
        cardBordered: true,
        panelExpand: false,
        controlSize: 'md',
        navMode: THEME_ENUM.NAV_MODE_LIGHT,
        layout: {
            type: THEME_ENUM.LAYOUT_TYPE_MODERN,
            sideNavCollapse: false,
        },
    };
    return themeConfig;
}