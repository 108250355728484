import React from 'react'
import { useTranslation } from 'react-i18next';

const Total = props => {
	const {t} = useTranslation();
	const { total } = props;
	return (
	  <div className="pagination-total">
		{t('translation.total')} <span>{total}</span> {t('translation.items')}
	  </div>
	);
  };

export default Total
